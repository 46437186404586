<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>E-Posta Bilgisi</b-card-title>
      <b-form-checkbox
        v-model="dataItem.email_information"
        value="1"
        switch
      >
        E-Posta Gönderilsin
      </b-form-checkbox>
    </b-card-header>
    <b-card-body>
      <template v-if="dataItem.email_information === '1'">
        <subject-text />
        <message-body />
      </template>
      <b-alert
        v-else
        variant="info"
        show
      >
        <div class="alert-body text-center">
          Otomatik e-posta bilgilendirmesi gönderilmeyecektir.
        </div>
      </b-alert>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody, BFormCheckbox, BAlert,
} from 'bootstrap-vue'
import SubjectText from '@/views/Admin/Customer_accounts/elements/email-info/SubjectText.vue'
import MessageBody from '@/views/Admin/Customer_accounts/elements/email-info/MessageBody.vue'

export default {
  name: 'EmailInfoCard',
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    BFormCheckbox,
    BAlert,
    SubjectText,
    MessageBody,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
  },
}
</script>
