<template>
  <div>
    <template v-if="!dataItem.id">
      <b-form-group
        v-if="!dataItem.id"
        label="Otomatik Parola"
        label-for="autopassword"
      >
        <b-form-checkbox
          id="autopassword"
          v-model="autoPassword"
          switch
        />
      </b-form-group>
      <b-form-group
        v-if="!autoPassword"
        label="Parola"
        label-for="password"
      >
        <validation-provider
          #default="{ errors }"
          name="Parola"
          rules="required"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password"
              v-model="dataItem.password"
              :type="passwordFieldType"
              placeholder="Parola"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </template>
    <template v-else>
      <b-form-group
        v-if="dataItem.id"
        label="Yeni Parola"
        label-for="autopassword"
      >
        <b-form-checkbox
          id="autopassword"
          v-model="newPassword"
          switch
        />
      </b-form-group>
      <b-form-group
        v-if="newPassword"
        label="Parola"
        label-for="password"
      >
        <validation-provider
          #default="{ errors }"
          name="Parola"
          rules="required"
        >
          <b-input-group class="input-group-merge">
            <b-form-input
              id="password"
              v-model="dataItem.password"
              :type="passwordFieldType"
              placeholder="Parola"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="passwordToggleIcon"
                class="cursor-pointer"
                @click="togglePasswordVisibility"
              />
            </b-input-group-append>
          </b-input-group>
          <small class="text-danger">{{ errors[0] }}</small>
        </validation-provider>
      </b-form-group>
    </template>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'

export default {
  name: 'Password',
  components: {
    BFormGroup,
    BFormInput,
    BInputGroup,
    BInputGroupAppend,
    BFormCheckbox,
    ValidationProvider,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      required,
      autoPassword: true,
      newPassword: false,
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
  },
  created() {
    localize('tr')
  },
}
</script>
