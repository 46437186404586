<template>
  <b-form-group>
    <b-form-input
      v-model="dataItem.email_subject"
      placeholder="E-Posta Konusu"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormInput } from 'bootstrap-vue'

export default {
  name: 'SubjectText',
  components: {
    BFormGroup,
    BFormInput,
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
  },
}
</script>
