<template>
  <div>
    <b-form-group
      label="Metin"
      label-for="content"
    >
      <quill-editor
        v-model="dataItem.email_body"
        :options="editorOption"
        class="bg-white text-black-50"
      >
        <div
          id="content_toolbar"
          slot="toolbar"
        >
          <editor-toolbar />
        </div>
      </quill-editor>
    </b-form-group>
    <b-alert
      variant="warning"
      show
    >
      <div class="alert-body text-center">
        Firma bilgisi, E-Posta ve Parola bilgisi sistem tarafından otomatik olarak eklenmektedir.
      </div>
    </b-alert>
  </div>
</template>

<script>
import {
  BFormGroup,
  BAlert,
} from 'bootstrap-vue'
// eslint-disable-next-line
import 'quill/dist/quill.core.css'
// eslint-disable-next-line
import 'quill/dist/quill.snow.css'
// eslint-disable-next-line
import 'quill/dist/quill.bubble.css'

import { quillEditor } from 'vue-quill-editor'
import EditorToolbar from '@/layouts/components/common/EditorToolbar.vue'

export default {
  name: 'MessageBody',
  components: {
    BFormGroup,
    BAlert,
    quillEditor,
    EditorToolbar,
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: '#content_toolbar',
        },
        theme: 'snow',
      },
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
  },
}
</script>
