<template>
  <validation-observer ref="simpleRules">
    <item-form />
    <save-button
      :save-action="submitForm"
      :is-disabled="submitStatus"
    />
  </validation-observer>
</template>
<script>
import { ValidationObserver, localize } from 'vee-validate'
import { required } from '@validations'
import ItemForm from '@/views/Admin/Customer_accounts/ItemForm.vue'
import SaveButton from '@/layouts/components/common/SaveButton.vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  name: 'Add',
  components: {
    ItemForm,
    SaveButton,
    ValidationObserver,
  },
  data() {
    return {
      required,
      submitStatus: false,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
    saveData() {
      return this.$store.getters['customerAccounts/dataSaveStatus']
    },
  },
  watch: {
    saveData(val) {
      if (val.status === true) {
        this.showToast({
          variant: 'success', title: 'İşlem Başarılı!', icon: 'CheckIcon', text: val.message,
        })
      } else if (val.status === false) {
        this.showToast({
          variant: 'danger', title: 'İşlem Hatası!', icon: 'XIcon', text: val.message,
        })
      }
    },
  },
  created() {
    this.$store.commit('customerAccounts/RESET_DATA_ITEM')
    localize('tr')
    this.getCustomer()
  },
  methods: {
    getCustomer() {
      this.$store.dispatch('customers/getDataItem', this.$route.params.id_customers)
    },
    submitForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.$store.dispatch('customerAccounts/emailControl', {
            email: this.dataItem.email,
          })
            .then(response => {
              if (response.status) {
                this.submitStatus = true
                this.dataItem.id_customers = this.$route.params.id_customers
                this.$store.dispatch('customerAccounts/saveData', this.dataItem)
              }
            })
        }
      })
    },
    showToast(params) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: params.title,
          icon: params.icon,
          text: params.text,
          variant: params.variant,
        },
      })
    },
  },
}
</script>
