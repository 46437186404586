<template>
  <div>
    <b-form-group
      label="E-Posta"
      label-for="email"
    >
      <validation-provider
        #default="{ errors }"
        name="E-Posta"
        rules="required|email"
      >
        <b-form-input
          id="email"
          v-model="dataItem.email"
          placeholder="E-Posta"
          @change="controlReset"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-alert
      v-if="emailControl.id"
      show
      variant="danger"
    >
      <div class="alert-body">
        <p><strong>Hesap Hatası!</strong></p>
        <p class="mb-1">
          E-Posta adresi başka bir hesap tarafından kullanılmaktadır.
        </p>
        <b-button
          variant="danger"
          size="sm"
          :to="'/app/customers/accounts/edit/' + emailControl.id"
          target="_blank"
        >
          Hesabı Görüntüle
        </b-button>
      </div>
    </b-alert>
  </div>
</template>

<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput, BAlert, BButton,
} from 'bootstrap-vue'
import { required } from '@validations'

export default {
  name: 'Email',
  components: {
    BFormGroup,
    BFormInput,
    BAlert,
    BButton,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    dataItem() {
      return this.$store.getters['customerAccounts/dataItem']
    },
    emailControl() {
      return this.$store.getters['customerAccounts/emailControl']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    controlReset() {
      this.emailControl.id = null
      this.emailControl.status = null
    },
  },
}
</script>
